/* .mycarousel{
    width:1200px;
}  */

.mycarousel .carousel-caption{
    color:white;
    /* background-color: black; */
    /* background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; */
    background-color: rgba(0, 0, 0, .5);
    border-radius: 25px;
}

.mycarousel img {
    width: 100%;
    height: auto;
    max-height: 600px; /* You can adjust this value based on your desired max height for images. */
}
